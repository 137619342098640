import * as Sentry from "@sentry/nextjs";

export const sentrySpanWrap = async (wrappedFunction, name, description) => {
  const transaction = Sentry.getCurrentHub()
    .getScope()
    .getTransaction();
  if (transaction) {
    const span = transaction.startChild({
      op: "http",
      description: `${name} ${description}`,
    });

    try {
      const result = await wrappedFunction();
      span.setStatus("ok");
      span.finish();
      return result;
    } catch (err) {
      span.setStatus("fetch_error");
      span.finish();
      return false;
    }
  }
};

export const sentrySpanWrapNotAsync = (
  wrappedFunction,
  name,
  description,
  type = "task"
) => {
  const transaction = Sentry.getCurrentHub()
    .getScope()
    .getTransaction();
  if (transaction) {
    const span = transaction.startChild({
      op: type,
      description: `${name} ${description}`,
    });

    try {
      const result = wrappedFunction();
      span.setStatus("ok");
      span.finish();
      return result;
    } catch (err) {
      span.setStatus("error");
      span.finish();
      return false;
    }
  }
};
