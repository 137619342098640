
import axios from 'axios';
import { API_KEYS, PROMOTIONAL_OFFER } from '../__constants__';
import _ from 'lodash';
const { API_URL, PROFESSIONAL_URL, API_URL_INTERNAL, PROFESSIONAL_URL_INTERNAL } = API_KEYS;
import { sentrySpanWrap } from '../utils/sentryHelpers';

export const fetchProfileData = async (id, isInternal = false, existingProfile = null) => {
  return await sentrySpanWrap(() => fetchAllDataForProfile(id, isInternal, existingProfile), 'fetchProfileData', 'fetching all data for profile');
}
const fetchAllDataForProfile = async (id, isInternal = false, existingProfile = null) => {

    let profile = existingProfile

    if (!profile) {
      profile = await fetchProfile(id, isInternal);
      if (!profile) {
        return false;
      }
    }

    const [services, promotions, initialReviews] = await Promise.all(
        [sentrySpanWrap(() => fetchServiceIds(id, isInternal), 'fetchServiceIds', 'fetching service ids'),
        sentrySpanWrap(() => fetchPromotions(id, isInternal), 'fetchPromotions', 'fetching promotions'),
        sentrySpanWrap(() => fetchInitialProfileReviews(id, isInternal), 'fetchInitialProfileReviews', 'fetching first set of profile reviews')
    ]);

    const PROFILE_DATA_STATE = {
        professionalId: id,
        serviceIds: {
            data: services.serviceIds,
        },
        parentServiceIds: {
            data: services.parentServiceIds
        },
        profile: {
            data: profile,
        },
        reviewStatistics: {
            data: await sentrySpanWrap(() => fetchReviewStatistics(id, isInternal), 'fetchReviewStatistics', 'fetching review statistics'),
        },
        professionalChecks: {
            data: await sentrySpanWrap(() => fetchProfessionalChecks(id, isInternal), 'fetchProfessionalChecks', 'fetching professional checks'),
        },
        promotions: {
            data: promotions.data,
            attributeIds: promotions.attributeIds,
        },
        fees: {
            data: [],
        },
        jobs: {
            data: await sentrySpanWrap(() => fetchJobs(id, isInternal), 'fetchJobs', 'fetching jobs'),
        },
        awards: {
            data: await sentrySpanWrap(() => fetchAwards(id, isInternal), 'fetchAwards', 'fetching awards'),
        },
        initialReviews: {
            data: initialReviews.data,
            totalCount: initialReviews.aggregate_data.total
        },
        reviewFilters: {
            data: await sentrySpanWrap(() => fetchReviewFilters(id, isInternal), 'fetchReviewFilters', 'fetching review filters')
        }

    };

    return PROFILE_DATA_STATE
}

export const fetchProfile = async (id, isInternal = false) => {
    return await sentrySpanWrap(() => fetchProfileOnlyData(id, isInternal), 'fetchProfile', 'fetching profile');
}

const fetchProfileOnlyData = async (id, isInternal = false) => {
  const res = await axios.get(`${isInternal ? API_URL_INTERNAL : API_URL}/v2/public/professionals/${id}/profile`)
        const profileData = _.get(res, 'data.data', []);
        return profileData
}

export const fetchInitialProfileReviews = async (id, isInternal = false) => {
    const res = await axios.get(`${isInternal ? API_URL_INTERNAL : API_URL}/v2/public/professionals/${id}/reviews?limit=5&offset=0`);
    return res.data
}

export const fetchReviewFilters = async (id, isInternal = false) => {
    const res = await axios.get(`${isInternal ? API_URL_INTERNAL : API_URL}/v2/public/professionals/${id}/review-filters`)
    return res.data.data
}

export const fetchServiceIds = async (id, isInternal = false) => {
    const res = await axios.get(`${isInternal ? API_URL_INTERNAL : API_URL}/v2/public/professionals/${id}/services`);
    return {
        serviceIds: _.get(res, 'data.data', []),
        parentServiceIds: _.get(res, 'data.aggregate_data.parent_ids', []),
    };
}

export const fetchReviewStatistics = async (id, isInternal = false) => {
    const res = await axios.get(`${isInternal ? API_URL_INTERNAL : API_URL}/v2/public/professionals/${id}/review-statistics`)
    const data = _.get(res, 'data.data', null);
    return data
}

export const fetchProfessionalChecks = async (id, isInternal = false) => {
    const res = await axios.get(`${isInternal ? API_URL_INTERNAL : API_URL}/v2/public/professionals/${id}/checks`)
    const data = _.get(res, 'data.data', null);
    return data
}

export const fetchPromotions = async (id, isInternal = false) => {
    const res = await axios.get(`${isInternal ? API_URL_INTERNAL : API_URL}/v2/public/professionals/${id}/promotions`);
    const promotionsData = _.get(res, 'data.data', []);

    return {
        data: promotionsData,
        attributeIds: promotionsData.length ? promotionsData.map(promotion => promotion.id) : [],
    }
}

export const fetchFees = async (id, firmId, isInternal = false) => {
    const urlSuffix = firmId ? `/${firmId}` : '';
    const res = await axios.get(`${isInternal ? PROFESSIONAL_URL_INTERNAL : PROFESSIONAL_URL}/${id}/new-fees${urlSuffix}`)
    const data = _.get(res, 'data', null);
    return data
}

export const fetchJobs = async (id, isInternal = false) => {
    const res = await axios.get(`${isInternal ? PROFESSIONAL_URL_INTERNAL : PROFESSIONAL_URL}/jobs/${id}?sort=-startDate`)
    const data = _.get(res, 'data', null);
    return data
}

export const fetchAwards = async (id, isInternal = false) => {
    const res = await axios.get(`${isInternal ? API_URL_INTERNAL : API_URL}/v2/public/professionals/${id}/awards`)
    const data = _.get(res, 'data', null);
    return data
}

export const fetchInitialReviewData = async (id, isInternal = false) => {
    const REVIEW_PARAMS = `limit=5`;

    return await axios
    .all([
        axios.get(`${isInternal ? API_URL_INTERNAL : API_URL}/v2/public/professionals/${id}/reviews?${REVIEW_PARAMS}`),
        axios.get(`${isInternal ? API_URL_INTERNAL : API_URL}/v2/public/professionals/${id}/review-summary`),
    ])
    .then(axios.spread((reviews, reviewSummary) => {
        return {
            reviews:  _.get(reviews, ['data', 'data'], null),
            servicesSummary: _.get(reviewSummary, ['data', 'data', 'financial_adviser_services'], []),
            savingsSummary: _.get(reviewSummary, ['data', 'data', 'client_savings_and_investments'], []),
            averageScores: _.get(reviewSummary, ['data', 'data', 'average_scores'], []),
            reviewsTotalCount: _.get(reviews, ['data', 'aggregate_data', 'total'], 0),
        }
    }))
    .catch(() => null)
}

export const fetchReviews = async (id, offset = 0, limit = 5, ratingFilter = null) => {
    if (ratingFilter !== null && !Number.isInteger(ratingFilter)) {
        return []
    }

    try {
        const REVIEW_PARAMS = `limit=${limit}&offset=${offset}${ratingFilter !== null ? `&tiers[]=${ratingFilter}` : ''}`
        const res = await fetch(`${API_URL}/v2/public/professionals/${id}/reviews?${REVIEW_PARAMS}`)
        const json = await res.json()
        return json.data
    } catch (e) {
        return []
    }
}
